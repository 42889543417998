html, body, .grid-container {
  height: 100%; 
  border: none;
  text-align: center;
  font-family: 'roboto';
  font-weight: 300;
  grid-gap: 24px; 
  margin: 8px;
}

@media (min-width: 700px) {
  html, body, .grid-container {
  margin: 15px;
}
}

body{
  background: #efefef;
  
}

.radarTitle {
  width: 220px;
  font-family: 'Oswald', 'sans serif' !important;
  font-weight: 400;
  font-size: 50px;
  display:block;
  position:absolute;
  margin-left: 0px;
  margin-top: 30px;
  text-align: center;
  color: white;
}

.radar {
  width: 220px;
  margin: 0 auto;
  padding-top: 80px;
  padding-bottom: 40px;
}

#app {
  background:white;
}
.header-logo {
  padding: 80px 0 80px 0 ;
}

.center-text {
  text-align: center!important;
}

h1.entry-title {
  font-size: 24px;
  line-height: 36px;
  font-weight: 300;
  max-width: 490px;
  margin: 0 auto;
  padding-bottom: 60px;
  text-align: center;
}

.heading {
  font-weight: 200;
}

.subheading {
  font-weight: 100;
}

.grid-container * {
  position: relative;
}

.grid-container *:after {
  position: absolute;
  top: 0;
  left: 0;
}


  .grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas: "Imagem-1" "Imagem-2" "Imagem-3" "Imagem-4" "Imagem-Outra";
  }


@media (min-width: 700px) {

.grid-container {
  margin: 0 120px;
  height: 600px;
  display: grid;
  grid-template-columns: 1fr 1.1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "Imagem-1 Imagem-2 Imagem-3" "Imagem-1 Imagem-4 Imagem-4" "Imagem-Outra Imagem-4 Imagem-4";
}

}

.Imagem-2 {
  cursor: pointer;
  border-radius: 4px;
  grid-area: Imagem-2;
  background: url('images/data-intelligence.jpeg');
  background-size: cover;
  background-position: center center;
}

.Imagem-3 {
  cursor: pointer;
  border-radius: 4px;
  grid-area: Imagem-3;
  background: url('images/back.jpeg');
  background-size: cover;
  background-position: center center;
}

.Imagem-4 {
  cursor: pointer;
  border-radius: 4px;
  grid-area: Imagem-4;
  background: url('images/studio.png');
  background-size: cover;
  background-position: center center;
}

.Imagem-1 {
  cursor: pointer;
  border-radius: 4px;
  grid-area: Imagem-1;
  background: url('images/front.webp');
  background-size: cover;
  background-position: center center;
}

.Imagem-Outra {
  cursor: pointer;
  border-radius: 4px;
  grid-area: Imagem-Outra;
  background: url('images/ops.jpeg');
  background-size: cover;
  background-position: center center;
}

section:hover{
  background-color:white;
      color: black;
    text-transform: uppercase;
  }
  
  section:hover span {
    color: #aaa;
    text-transform: uppercase;
  }
  
  section {
    text-transform: uppercase;
    font-family: 'roboto';
    color: transparent;
    display: flex;
    flex-flow: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    font-size: 18px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    font-weight: 300;
    background-color:transparent;
    transition:background-color 0.2s ease-in-out 0s;
  }



@media (max-width: 800px) {
  section {
    color: black;
    background-color:white;
    height: 150px
  }
  

}
  
  section span {
    font-size: 12px;
    width: 100%;
  }
  

.transclass {
  text-align: center;
  vertical-align: center;
  background-color: transparent;
  margin: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out 0s;
  outline: none;
}

@media (max-width: 800px) {
.transclass {
  margin: 20px;
  opacity: 0.9;
}

}

.big-card {
  height: 80%;
}


.small-card {
  height: 60%;
}

.heart {
  animation: heartbeat 1s infinite;
  font-size: 20px;
    color: red;
  display: inline-block;
}

@keyframes heartbeat {
0% {
  transform: scale( .75);
}
20% {
  transform: scale( 1);
}
40% {
  transform: scale( .75);
}
60% {
  transform: scale( 1);
}
80% {
  transform: scale( .75);
}
100% {
  transform: scale( .75);
}
}
